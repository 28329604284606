'use strict';
var CAY = window.CAY || {};

(function($) {

  CAY.Helpers = {
    // Example Function
    windowHeightMatch: function() {
      var wH = $(window).height();
      $('.heightmatch').css('height', wH);
    },
    testFunction: function() {
      // Do stuff here
    }
  };
  
})(jQuery);
