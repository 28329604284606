'use strict';
jQuery.migrateMute = true;

(function($) {
	$(function() {

		// $('select').niceSelect();

		$(document).on('gform_post_render', function(event, form_id, current_page) {
			$('.gfield_select').niceSelect();
		});

		$('.ham').on('click', function() {
			$('#navicon').toggleClass('open');
			$('body').toggleClass('modal-open');
			$('.mobile-menu').toggleClass('open');
		});

		$('.mobile-menu li.menu-item-has-children span').on('click', function(e) {
			e.preventDefault();

			var targ = $(this);

			if (targ.parent().siblings().hasClass('sub-menu') && !$('.sub-menu').hasClass('slide')) {
				e.preventDefault();
				targ.parent().siblings().slideDown().addClass('slide');
				targ.addClass('slide');
				targ.text('–');
				return;
			} else if (targ.parent().siblings().hasClass('sub-menu') && targ.parent().siblings().hasClass('slide')) {
				e.preventDefault();
				targ.parent().siblings().slideUp().removeClass('slide');
				targ.removeClass('slide');
				targ.text('+');
				return;
			} else if (targ.parent().siblings().hasClass('sub-menu') && $('.sub-menu').hasClass('slide') && !targ.parent().siblings().hasClass('slide')) {
				e.preventDefault();
				$('.sub-menu').slideUp().removeClass('slide');
				$('.sub-menu').siblings().children('span').removeClass('slide');
				targ.parent().siblings().slideDown().addClass('slide');
				targ.addClass('slide');
			} else {
				return true;
			}
		});

		$(window).on('resize', function() {
			var wW = $(window).width();
			if (wW >= 992) {
				$('body').removeClass('modal-open');
				$('.mobile-menu').removeClass('open');
				$('#navicon').removeClass('open');
			}
		});

		$('.main-nav button').on('click', function(e) {

			var form = $('.main-nav form');
			var $inp = $('.main-nav form input');
			var inputVal = $('.main-nav form input').val();

			if (form.hasClass('search-open') && inputVal !== "" && inputVal !== null) {
				form.submit();
			} else if (form.hasClass('search-open')) {
				e.preventDefault();
				form.removeClass('search-open');
				$inp.removeClass('search-open');
				window.setTimeout(function() {
					$('.main-nav #s').blur();
				}, 100);
				return false;
			} else {
				e.preventDefault();
				form.addClass('search-open');
				$inp.addClass('search-open');
				window.setTimeout(function() {
					$('.main-nav #s').focus();
				}, 100);
				return;
			}
		});

		$('.volunteer-single .video-feature .img-const').on('click', function() {
			var $this = $(this);
			var vidSrc = $this.children('iframe').attr("src") + "?autoplay=1";
			$('.modal iframe').attr("src", vidSrc);

			setTimeout(function() {
				$('.volunteer-single .modal').addClass('open');
				$('body').addClass('vid-modal-open');
			}, 225);
		});

		$('.volunteer-single .modal').on('click', function() {
			$(this).removeClass('open');
			$('body').removeClass('vid-modal-open');
			var $iframe = $(this).find("iframe");
			var iframeSrc = $iframe.attr("src");
			var iframeClean = iframeSrc.replace('?autoplay=1', '');
			$iframe.attr("src", iframeClean);
		});

		$('.volunteer-single .the-slider').slick({
			autoplay: true,
			autoplaySpeed: 5500,
			infinite: true,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true
		});

		// Resource Group Accordion
		$('.resource-header').on('click', function() {
			$(this).parents('.resource-group').toggleClass('list-open');
			$(this).parents('.resource-group').find('.resource-links').slideToggle(300, function() {
				// do something on complete? 
			});

		});

		// FitVids.js for Elevate Live Streams
		$(".stream-frame").fitVids({
			customSelector: "iframe[src^='https://livestream.com']",
		});

		$('.tiled-gallery a').on('click', function(e) {
			e.preventDefault();
			e.stopPropagation();
			window.open(this.href, '_blank');
        });
        
        $('.tiled-gallery a').on('hover', function() {
            if ( $(this).siblings('.tiled-gallery-caption').length ) {
                //$(this).siblings('.tiled-gallery-caption').toggle();
            }

        });

        // Strategic Initiatives Cards
        var setClickCards = function() {
            var $cards = $('.click-card');
            if ( $cards.length ) {

                $cards.each(function() {
                    if ( $(this).find('a').length) {
                        $(this).css('cursor', 'pointer');
                        $(this).on('click', function(e) {
                            var link = $(this).find('a');
                            e.preventDefault();
                            e.stopPropagation();
                            if ( link.length ) {
                                if ( '_blank' === link.attr('target') ) {
                                    window.open( link.attr('href') );
                                } else {
                                    window.location = link.attr('href');
                                }
                            }
                        });
                    }
                });

            }
        };
        setClickCards();

        // Strategic Initiatives Partners
        $('body.page-strategic-initiatives .partner-list').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: true,
            dots: false,
            pauseOnHover: true,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 340,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });

        // Add Tooltips to Form Fields
        if ( $('.b-corp-form').length ) {

            // Add tooltip
            $('#field_7_18 legend, #field_8_10 label').each( function() {
                var label_text = $(this).html();
                $(this).html( label_text + ' <a href="#form-notes" title="More information" class="btn-form-info" data-modal-note">i</a>');
            });

            // Prevent Last Pass icon
            $('.b-corp-form').find('input[type=text]').attr('data-lpignore', true);
        }

        $('a.btn-form-info').on('click', function() {
            $(this).modal({
                modalClass: 'modalizer',  
            });
            return false;
        });
         
        

	});



})(jQuery);